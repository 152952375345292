import type { MetaDescriptor } from "@remix-run/node";

import { TMetadata } from "~/types/metadata";

export const getMetadata = ({
  title,
  description,
  siteUrl,
  image,
  twitterCreator,
  twitterSite,
  additionalMeta,
}: TMetadata): MetaDescriptor[] => {
  const meta: MetaDescriptor[] = [
    { title },
    { name: "description", content: description },
    { property: "og:url", content: siteUrl },
    { property: "og:title", content: title },
    { property: "og:description", content: description },
    { property: "og:image", content: image },
    { name: "twitter:card", content: "summary_large_image" },
    { name: "twitter:title", content: title },
    { name: "twitter:description", content: description },
    { name: "twitter:image", content: image },
  ];

  if (twitterCreator) {
    meta.push({ name: "twitter:creator", content: twitterCreator });
  }

  if (twitterSite) {
    meta.push({ name: "twitter:site", content: twitterSite });
  }

  if (additionalMeta) {
    meta.push(...additionalMeta);
  }

  return meta.filter((v) => !("content" in v) || !!v.content);
};
